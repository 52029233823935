import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "How to Build a Grid Inspector",
  "date": "2017-10-23",
  "promo": "grids",
  "description": "Learn how Grid Inspectors work by building your own!",
  "color": "#907aff"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We've seen that when building a Grid layout, you're working with an `}<a parentName="p" {...{
        "href": "/post/invisible-grid/"
      }}>{`invisible grid`}</a>{`. So you power up the `}<a parentName="p" {...{
        "href": "https://www.mozilla.org/en-US/firefox/developer/"
      }}>{`devtools`}</a>{` Grid Inspector and in your best Harry Potter voice chant `}<em parentName="p">{`Aparecium Grid!`}</em>{` Then just like magic, your invisible grid appears! This is both neat and frustrating. `}<em parentName="p">{`You`}</em>{` can't see or style your grid, but the devtools somehow can? It's cheating right?! In this post I'll show you how to create your own Grid Inspector - a super fun little project that will leave you with a better understanding of how this important tool works.`}</p>
    <p>{`In my `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` Mastery Game I wanted to teach how CSS Grid lines & gaps work in a fun and engaging way. So I created a custom Grid Inspector where each grid line is a pair of laser-connnected interceptor spaceships. It looks like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/grid-inspector.gif",
        "alt": "Space ship grid inspector"
      }}></img></p>
    <p>{`To show you how to build one like it let's start with the basics.`}</p>
    <h2>{`Positioning the Inspector`}</h2>
    <p>{`We'll build a grid inspector using this basic grid layout as an example:`}</p>
    <CodeExample theme="lightgrid" live={true} showCSS={`
    #container{
      display: grid;
      width: 100%;
      height: 350px;
      grid-template-columns: [full-start] 1fr 1fr [full-end];
      grid-template-rows: 100px 1fr 100px;
      grid-gap: 20px;
    }\n
    .purple {
      grid-row: span 2;
    }\n
    .blue {
      grid-column: span 2;
    }
  `} html={`
    <div id="container">
      <div class="item grey"></div>
      <div class="item purple"></div>  
      <div class="item orange"></div>
      <div class="item blue"></div>
    </div>
  `} mdxType="CodeExample" />
    <p>{`The first thing we'll need is to create a `}<inlineCode parentName="p">{`div`}</inlineCode>{` and place it exactly over the top of the `}<inlineCode parentName="p">{`display:grid`}</inlineCode>{` element.`}</p>
    <CodeExample live={true} theme="lightgrid" showJS={`
    const container = document.getElementById("container");
    const inspector = document.createElement('div');
    inspector.setAttribute('style', \`
      position: absolute;
      height: \${container.offsetHeight}px;
      width: \${container.offsetWidth}px;
      top: \${container.offsetTop}px;
      left: \${container.offsetLeft}px;
      background: rgba(58, 250, 8, .5);
    \`);
    document.body.appendChild(inspector);
  `} fixedCSS={`
    #container{
      display: grid;
      width: 100%;
      height: 350px;
      grid-template-columns: [full-start] 1fr 1fr [full-end];
      grid-template-rows: 100px 1fr 100px;
      grid-gap: 20px;
    }
    .purple {
      grid-row: span 2;
    }
    .blue {
      grid-column: span 2;
    }
  `} html={`
    <div id="container">
      <div class="item grey"></div>
      <div class="item purple"></div>  
      <div class="item orange"></div>
      <div class="item blue"></div>
    </div>
  `} mdxType="CodeExample" />
    <h2>{`Getting the Grid Data`}</h2>
    <p>{`Now that we have our inspector div placed right over top of the element we're going to be inspecting, we want to ask the browser about the rows, columns, and gaps in our grid:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const styles = window.getComputedStyle(container)

const rows = styles.getPropertyValue('grid-template-rows')
const columns = styles.getPropertyValue('grid-template-columns')

const rowGap = styles.getPropertyValue('grid-row-gap')
const columnGap = styles.getPropertyValue('grid-column-gap')
`}</code></pre>
    <p>{`The browser really throws us a bone here by returning the current `}<em parentName="p">{`pixel`}</em>{` size of things, rather than in the units specified. Our example's two columns are given `}<inlineCode parentName="p">{`1fr`}</inlineCode>{` width, but querying their property value returns a string of `}<inlineCode parentName="p">{`"[full-start] 300px 300px [full-end]"`}</inlineCode>{`. This tells us the `}<em parentName="p">{`names`}</em>{` of the grid lines and the `}<em parentName="p">{`sizes`}</em>{` of the tracks (rows and columns). See `}<a parentName="p" {...{
        "href": "/post/naming-css-grid-lines/"
      }}>{`Naming your CSS Grid Lines`}</a>{` to understand exactly how that syntax works.`}</p>
    <h2>{`Transforming the Grid Data`}</h2>
    <p>{`But for our Grid Inspector we're actually more interested in displaying the `}<strong parentName="p">{`lines`}</strong>{` that sit on the edges of the grid and between each of the tracks. So we'll convert this info into an array of objects that describe the position and name of each grid line.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// convert the rows and columns into a format we can work with
const columnData = parseGridTemplate(columns, columnGap)
const rowData = parseGridTemplate(rows, rowGap)
`}</code></pre>
    <p>{`See this `}<a parentName="p" {...{
        "href": "https://codepen.io/geddski/pen/WZByGb?editors=0110"
      }}>{`codepen`}</a>{` if you want to dive into our `}<inlineCode parentName="p">{`parseGridTemplate`}</inlineCode>{` function.`}</p>
    <p>{`So the browser gave us this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// columns
'[full-start] 300px 300px [full-end]'

// columnGap
20
`}</code></pre>
    <p>{`and we transformed it into this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`;[
  {
    start: -1,
    end: 1,
    name: 'full-start',
  },
  {
    start: 299,
    end: 301,
    name: null,
    gap: 20,
  },
  {
    start: 619,
    end: 621,
    name: 'full-end',
  },
]
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`start`}</inlineCode>{` and `}<inlineCode parentName="p">{`end`}</inlineCode>{` values are where we want our grid lines to show up, and the middle line has a `}<inlineCode parentName="p">{`20px`}</inlineCode>{` gap (outer lines don't get `}<a parentName="p" {...{
        "href": "/post/shiny-new-grid-tools/"
      }}>{`gaps`}</a>{` applied to them which is fantastic).`}</p>
    <p>{`We now have a format that describes all the grid lines we want to render. This is where a template framework like React/Angular/Vue would come in handy. But let's make this one in vanilla JS for this exercise.`}</p>
    <h2>{`Rendering the Grid Lines`}</h2>
    <p>{`Taking our grid data, we can now create a simple styled `}<inlineCode parentName="p">{`div`}</inlineCode>{` for each grid line and put it where it goes, accounting for any grid gap sizes the lines might have. We'll also extend the lines out a bit farther than the element to make them easier to see.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// extend the lines a bit so we can see them better
const extendLines = 20

// render a line for each vertical grid line
columnData.forEach(item => {
  const line = document.createElement('div')
  line.setAttribute(
    'style',
    \`
    background: rgba(0, 0, 0, 1);
    position: absolute;
    height: \${container.offsetHeight}px;
    width: \${item.end - item.start + (item.gap || 0)}px;
    left: \${item.start}px;
  \`
  )
  inspector.appendChild(line)
})

// render a line for each horizontal grid line
rowData.forEach(item => {
  const line = document.createElement('div')
  line.setAttribute(
    'style',
    \`
    background: rgba(0, 0, 0, 1);
    position: absolute;
    width: \${container.offsetWidth}px;
    height: \${item.end - item.start + (item.gap || 0)}px;
    top: \${item.start}px;
  \`
  )
  inspector.appendChild(line)
})
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/render-grid-lines.jpg",
        "alt": "render grid lines"
      }}></img></p>
    <p>{`Here's the `}<a parentName="p" {...{
        "href": "https://codepen.io/geddski/pen/BweVXK"
      }}>{`codepen example`}</a>{` of this.`}</p>
    <p>{`See, it's not black magic! We just leverage the info the browser gives us, throw in a wee bit o' JS, add some styling spices and `}<em parentName="p">{`voilà`}</em>{`. Our very own custom Grid Inspector! I went a little crazy on mine for `}<a parentName="p" {...{
        "href": "https://gridcritters"
      }}>{`Grid Critters`}</a>{`, using the positioning to render sprites to a canvas, animated the ships flying into their positions, and even made fun little thruster animations for when when grid gaps change size:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/grid-inspector-closeup.gif",
        "alt": "animated css grid gaps"
      }}></img></p>
    <p>{`Now you know how Grid Inspectors like the one in `}<a parentName="p" {...{
        "href": "https://www.mozilla.org/en-US/firefox/developer/"
      }}>{`Firefox`}</a>{` work (only with a lot more features and polish).`}</p>
    <p>{`From here you can take yours in any awesome direction you can imagine. I'd love to see where you take it! Send me a video on twitter (`}<a parentName="p" {...{
        "href": "//twitter.com/geddski"
      }}>{`@geddski`}</a>{`) and show me what you make!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      